@font-face {
  font-family: "Calibri";
  src: local("Calibri"),
    url("./assets/fonts/calibri-regular.ttf") format("truetype"),
    url("./assets/fonts/calibri-regular.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.svg#Calibri")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Calibri Bold";
  src: local("Calibri Bold"),
    url("./assets/fonts/calibri-bold.ttf") format("truetype"),
    url("./assets/fonts/calibri-bold.woff") format("woff");
  font-weight: 700;
}

b {
  font-family: "Calibri";
}

/* @font-face {
  font-family: "Calibri";
  src: url("//db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.eot");
  src: url("//db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.svg")
      format("svg");
  font-style: normal;
}

@font-face {
  font-family: "Calibri Bold";
  src: url("//db.onlinewebfonts.com/t/c8980f67879f6f08737d47c2ecb1cc43.eot");
  src: url("//db.onlinewebfonts.com/t/c8980f67879f6f08737d47c2ecb1cc43.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/c8980f67879f6f08737d47c2ecb1cc43.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/c8980f67879f6f08737d47c2ecb1cc43.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/c8980f67879f6f08737d47c2ecb1cc43.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/c8980f67879f6f08737d47c2ecb1cc43.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
