.headerContainer {
  display: flex;
  height: 100px;
  position: relative;
}
.header {
  width: 100%;
  background-color: white;
  display: flex;
  overflow: hidden;
  flex-direction: row;
}

.headerLeft {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  padding-left: 1.5%;
  width: 15%;
  z-index: 1;
}

.headerLeftBorder {
  border-top: 100px solid white;
  border-right: 50px solid transparent;
  align-self: flex-start;
  justify-self: flex-start;
  z-index: 2;
}

.headerRightBorder {
  border-bottom: 100px solid #008eaa;
  border-left: 50px solid transparent;
  z-index: 2;
  @media only screen and (max-width: 320px) {
    visibility: hidden;
  }
}

.headerBorder {
  height: 9px;
  width: 100%;
  background-color: #fbd872;
  position: absolute;
  top: 0;
}

.headerBorder.splash {
  width: 100%;
  bottom: 0;
  z-index: 0;
  top: auto;
}

.headerMiddle {
  background-color: #3c3e3f;
  flex-direction: column;
  flex: 3;
  z-index: 1;
  /* width: 51%; */
}

.headerMiddleContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  height: 100%;
}

.headerMiddleTextContainer {
  align-self: flex-end;
  padding-bottom: 6px;
  margin: 0 36px 0 36px;
  color: "#fbd872";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftSection {
  display: inline-block;
  flex: 1;
}

.headerTitleContainer {
  align-self: flex-end;
  padding-bottom: 6px;
  flex-direction: column;
  display: flex;
}

.headerTitle {
  color: #f2f2f2;
  font-size: 49px;
}

.headerTitle.small {
  color: #f2f2f2;
  font-size: 20px;
  line-height: 23px;
  align-items: flex-end;
}

.headerTitle.bottom {
  display: none;
}

.headerText {
  color: #fbd872;
  font-size: 20px;
  font-weight: bold;
  font-family: "Calibri Bold";
}

.dateText {
  color: #fbd872;
  font-size: 16px;
  font-weight: normal;
  margin: 6px 0 6px 0;
}
.date {
  margin-right: 30px;
}

.vesselModeText {
  font-weight: bold;
  font-family: "Calibri Bold";
  font-size: 40px;
}

.headerRight {
  background-color: #008eaa;
  align-items: center;
  display: flex;
  /* padding-left: 36;
  padding-right: 51; */
  width: 34%;
  color: white;
  justify-content: space-around;
  z-index: 2;
  /* clip-path: polygon(50px 0, 100% 0, 100% 100%, 0% 100%); */
}

.headerMiddle.splash {
  background-color: #003865;
  /* max-height: 100%; */
}

.headerTitle.splash {
  margin-left: 55px;
}

.headerRightBorder.splash {
  border-bottom: 100px solid #003865;
  border-left: 50px solid transparent;
  z-index: 2;
}

.headerRightBorder.splash.bottom {
  border-bottom: 100px solid white;
  border-left: 50px solid transparent;
  z-index: 2;
  width: auto;
}

.leftHeader {
  display: flex;
  flex: 1;
}

@media (max-width: 576px) {
  .headerContainer {
    flex-direction: column;
    height: 100px;
  }
  .headerTitle {
    display: none;
  }
  .headerBorder {
    height: 4px;
  }
  .headerLeftBorder {
    border-top: 76px solid white;
    border-right: 38px solid transparent;
    align-self: flex-start;
    justify-self: flex-start;
    z-index: 2;
  }
  .headerText {
    font-size: 14px;
  }
  .dateText {
    font-size: 12px;
  }
  .headerTitle.bottom {
    display: inline;
    font-size: 20px;
    text-align: left;
    flex: 1;
    white-space: nowrap;
  }
  .headerRightBorder {
    display: none;
  }
  .header {
    flex-direction: column;
  }
  .leftHeader {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  .headerRight {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    justify-content: space-around;
    padding: 0 1.5% 0 1.5%;
  }
  .vesselModePlaceholder {
    width: 60px;
    font-size: 16px;
  }
  .vesselModeText {
    font-size: 20px;
    text-align: right;
    flex: 1;
  }
  #electricalText {
    display: none;
  }
  .headerMiddleTextContainer {
    flex-direction: row;
    margin: 0;
    padding: 0px;
  }
  .dataDelay {
  }
  .date {
    margin-right: 16px;
  }

  .leftSection {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
  }
}
