* {
  box-sizing: border-box;
  user-select: none;
  -webkit-appearance: none;
}

h3 {
  color: "#53575A";
  font-weight: bold;
  font-family: "Calibri Bold";
  font-size: 16px;
  line-height: 16px;
  text-align: right;
}

.splash {
  width: 100%;
  height: 100%;
}

.splashbg {
  background-image: url("../src/assets/splash_png.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.splashBottomBar {
  background-color: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100px;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.splashBottomRightBar {
  background-color: white;
  width: 100%;
  flex: 1;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(50px 0%, 100% 0, 100% 100%, 0% 100%);
}

.splashBottomMiddle {
  background-color: transparent;
  width: 51%;
  position: "relative";
  z-index: 0;
}

.main {
  background: #f2f2f2;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.invisible {
  height: unset;
  visibility: hidden;
}

.hidden {
  display: none;
}

.main.viewport {
  /* height: 100vh; */
}

.dataCol {
  min-height: 260px;
  /* height: 24.07vh; */
}
#emissionCol,
#batteryCol,
#ABBCol,
#engineCol {
  min-height: 24.07vh;
}

.container {
  margin: 10px;
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dataRow {
}
.dataRow.main {
  height: 260px;
}

.dataRow.middleRow {
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: none;
}

.ship {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.innerShip {
  position: absolute;
  width: 100%;
  height: 100%;
}

#rightShipArrow,
#leftShipArrow {
  position: absolute;
  top: 48.5%;
}

#rightShipArrow {
  right: 22%;
}

#leftShipArrow {
  left: 23.5%;
}

#topLeftShipArrow {
  position: absolute;
  top: 46%;
  left: 11.5%;
}

#bottomLeftShipArrow {
  position: absolute;
  top: 56%;
  left: 10%;
}

.navContainer {
  display: flex;
  justify-content: flex-end;
  /*  margin-top: 0.9vh;
  margin-bottom: 0.9vh; */
}

.navContainer.bottom {
  align-items: flex-end;
  flex: 1;
  height: 100%;
}

.navButtonContainer {
  max-width: 4%;
  position: fixed;
  top: 39%;
}

h1 {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}

#map {
  width: 100%;
  height: 100%;
  position: absolute;
}

.map.hidden {
  display: none;
}

.infoContent {
  display: flex;
  flex-direction: column;
  margin-right: 2.6vw;
  flex: 3;
  align-self: flex-start;
}

.infoHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 1.8vh;
}

.weTechText {
  color: #003865;
  font-size: 50px;
  text-align: center;
  font-weight: 700;
  font-family: "Calibri Bold";
}

.line {
  border: 1px solid #8c8f90;
  flex: 1;
}

.infoText {
  font-size: 1.25rem;
  color: #3c3e3f;
}

.infoText a {
  color: #008aab;
  text-decoration: none;
  padding-top: 0px;
  margin-top: 0px;
}

.infoTextHeader {
  font-weight: bold;
  font-family: "Calibri Bold";
  font-size: 30px;
  line-height: 37px;
  color: #53575a;
  margin-right: 10%;
}

.infoTextHeader.large {
  font-size: 50px;
  line-height: normal;
  margin-bottom: 0px;
}

.partnersInfo {
  position: absolute;
  top: 0;
  right: 0;
  align-self: center;
  font-size: 20px;
}

.partnersInfo.emission {
  position: unset;
  align-self: flex-end;
}

.partnersInfo a {
  color: #008aab;
}

.closeButton {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  max-width: 40px;
  padding: 2px;
  border-radius: 4px;
  border: 2px solid #c9cdd1;
  background-color: #53575a;
  padding: 8px;
  top: 0;
}

@media (max-width: 1440px) {
  .navButtonContainer {
    position: static;
  }
}

@media (max-width: 1400px) {
  h1 {
    font-size: 16px;
  }
  .infoTextHeader.large {
    font-size: 38px;
  }
  .infoText {
    font-size: 1rem;
  }
  .weTechText {
    font-size: 38px;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 16px;
  }
  .main {
    height: 100%;
    min-height: 100vh;
  }
  .ship {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  #topShipArrow {
    top: 15%;
  }
  #middleShipArrow {
    top: 67%;
  }
  #leftShipArrow,
  #rightShipArrow {
    top: 79%;
    width: 26px;
  }
  #topShipArrow,
  #middleShipArrow {
    width: 26px;
  }
  #leftShipArrow {
    left: 38%;
  }
  #rightShipArrow {
    left: 55%;
  }
  .infoTextHeader {
    font-size: 24px;
    text-align: left;
    line-height: normal;
    margin-right: none;
  }
  .partnersInfo {
    position: unset;
    font-size: 12px;
    margin-left: 20px;
    padding-top: 2.5vh;
  }
  .partnersInfo.a {
    font-weight: 400;
  }
}
